import { Link } from 'gatsby';
import { FaPlay } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { deviceMax, deviceMin } from '../../styles/MediaQuery';
import { blue, blueDark, containerWidth, darkGreen, greyPathLink, limaBackground, white } from '../../styles/Variables';

interface ContainerProps {
    readonly isBanner?: boolean;
    imgUrl?: string;
    readonly color?: string | null;
}

export const Container = styled.div<ContainerProps>`
    ${(props) =>
        props.isBanner
            ? `background: ${
                  props.color === 'white'
                      ? `url(${props.imgUrl}), linear-gradient(180deg,#c9c9c9,${white}) repeat scroll 0 0 transparent`
                      : props.color === 'black'
                      ? `url(${props.imgUrl}), linear-gradient(180deg,#000,#606060) repeat scroll 0 0 transparent`
                      : `url(${props.imgUrl}), linear-gradient(
                180deg,#00306d,#054990) repeat scroll 0 0 transparent`
              };
            position: relative;
            background-repeat: no-repeat;
            background-position: center right;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            align-items: center;
            min-height: 14.5rem;
            
            @media ${deviceMax.tablet} {
                background-size: cover;
                background-position: 100%;
                min-height: 0;
                height: 10rem;
            }
        `
            : `background: linear-gradient(135deg, ${blueDark} 0%, ${blueDark} 40%, ${blue} 60%, ${blue} 100%);`}
`;

interface TitleBoxInterface {
    readonly isYoutube?: boolean;
}

export const TitleBox = styled.div<TitleBoxInterface>`
    display: flex;
    max-width: ${containerWidth};
    width: 100%;
    align-items: flex-start;

    @media ${deviceMax.tablet} {
        display: none;
    }

    h1 {
        color: ${white};
        font-size: 38px;
        margin-top: 31.075px;
        margin-bottom: 31.075px;
        margin: ${(props) => props.isYoutube && '0'};
        letter-spacing: 0.05rem;
    }

    @media ${deviceMax.laptop} {
        padding-left: 30px;
    }

    @media (min-width: 947px) {
        padding-left: 15px;
    }

    @media ${deviceMin.laptop} {
        padding-left: 0;
    }
`;

export const YoutubeWrapper = styled.div`
    display: flex;
    max-width: ${containerWidth};
    width: 100%;
    align-items: flex-start;
    margin-left: -10px;
    margin-top: 0.5rem;
    padding-bottom: 0.875rem;
    box-sizing: border-box;

    @media ${deviceMax.tablet} {
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    @media ${deviceMax.laptop} {
        padding-left: 20px;
    }

    @media (min-width: 947px) {
        padding-left: 15px;
    }

    @media ${deviceMin.laptop} {
        padding-left: 0;
    }
`;

export const Youtube = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media ${deviceMax.tablet} {
        flex-direction: column;
    }
`;

export const YoutubeText = styled.p`
    color: ${white};
    font-size: 1.5rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    margin: 0;
    font-weight: 400;
    margin-top: 0.1rem;
`;

export const YoutubePlay = styled.div`
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: linear-gradient(63rad, ${darkGreen} 0%, ${limaBackground} 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.7rem;

    @media ${deviceMax.tablet} {
        margin-right: 0;
        margin-bottom: 0.7rem;
    }
`;

export const YoutubePlayIcon = styled(FaPlay)`
    fill: white;
`;

export const HeaderLink = styled(Link)`
    color: ${greyPathLink};
    font-family: Arial;
    text-decoration: none;
    font-size: 13px;

    &:hover {
        color: white;
    }
`;

export const HeaderItem = styled.li`
    color: ${greyPathLink};
    font-family: Arial;
    font-size: 13px;
    padding: 0;

    &:not(:first-child) {
        padding: 0 1em 0 0;

        &::before {
            color: ${greyPathLink};
            content: '.';
            font-size: 20pt;
            position: relative;
            top: -3px;
            right: 5px;
        }
    }

    &:first-child {
        padding: 0 1em 0 0;

        &::before {
            color: ${greyPathLink};
            content: '';
            font-size: 20pt;
            position: relative;
            top: -3px;
            right: 0;
            opacity: 0;
        }
    }
`;

interface HeaderItemsProps {
    readonly isBanner?: boolean;
}

export const HeaderItems = styled.ul<HeaderItemsProps>`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 55px;
    max-width: 100%;
    width: ${containerWidth};
    margin: 0 auto;

    ${(props) =>
        props.isBanner &&
        `
        @media ${deviceMax.tablet} {
            display: none;
        }    
    `};

    @media ${deviceMax.laptop} {
        padding-left: 15px;
    }
`;

const animateBanner = keyframes`
  0% {
    right: calc(10%);
    transform: rotate(10deg);
  }
  
  50% {
    transform: rotate(-10deg);
  }
  
  81% {
    transform: rotate(8deg);
  }
  
  95% {
    transform: rotate(-1deg);
  }
  
  100% {
    right: calc((100% - 900px)/2);
    transform: rotate(0deg);
  }
`;

interface AnimatedBannerInterface {
    readonly imgUrl: string;
}

export const AnimatedBanner = styled.div<AnimatedBannerInterface>`
    background: url(${(props) => props.imgUrl});
    width: 440px;
    height: 110%;
    position: absolute;
    top: -5px;
    right: calc((100% - 900px) / 2);
    background-repeat: no-repeat !important;
    background-size: contain;
    animation-name: ${animateBanner};
    animation-duration: 2s;
    animation-timing-function: 1;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;
