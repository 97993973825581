import { Link } from 'gatsby';
import styled from 'styled-components';
import { deviceMax } from '../../styles/MediaQuery';
import {
    blue,
    darkGreen,
    lightGraishButton,
    lightGray,
    lightGrayishBlue,
    lightGreyButton,
} from '../../styles/Variables';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 3em;

    @media ${deviceMax.tablet} {
        justify-content: flex-start;
        margin: 0 auto;
        width: calc(100% - 4.8em);
        margin-top: 3.5em;
    }

    @media ${deviceMax.mobileL} {
        width: calc(100% - 4em);
        margin-top: 1em;
    }
`;

export const HeaderH1 = styled.h1`
    color: ${blue};
    font-size: 2.5em;
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 44px;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
`;

export const HeaderH2 = styled.h2`
    color: ${blue};
    font-size: 1.5em;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.01em;
    width: 100%;
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;
`;

export const MailLink = styled.a`
    color: ${darkGreen};
    font-size: 0.9em;
    text-decoration: none;
`;

export const ErrorLink = styled(Link)`
    border: 1px solid ${lightGray};
    border-radius: 5px;
    min-width: 150px;
    text-align: center;
    text-decoration: none;
    color: ${darkGreen};
    background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
    font-family: Arial, Helvetica, sans-serif;
    padding: 12px 30px;
    font-size: 0.9em;
    line-height: 1em;

    &:hover {
        background: ${lightGrayishBlue};
    }
`;

export const LinkWrapper = styled.div`
    margin: 3em 0;
`;
